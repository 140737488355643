/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */


.seat0 {
  width: 30px;
  height: 40px;
  background-color: #21bf73 !important;
  justify-content: center;
  border-color: #000 !important;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-width: 6px;
  border-radius: 0 0 20px 20px;
  margin-left: 3px;
  margin-bottom: 8px;
}

.seat0 label {
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px
}

.seat1 {
  width: 30px;
  height: 40px;
  background-color: #8c0000 !important;
  justify-content: center;
  border-color: #000 !important;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-width: 6px;
  border-radius: 0 0 20px 20px;
  margin-left: 3px;
  margin-bottom: 8px;

}

.seat1 label {
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px
}

.seat2 {
  width: 30px;
  height: 40px;
  background-color: #545454 !important;
  justify-content: center;
  border-color: #000 !important;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-width: 6px;
  border-radius: 0 0 20px 20px;
  margin-left: 3px;
  margin-bottom: 8px;
}

.seat2 label {
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px
}

.seat4 {
  width: 30px;
  height: 40px;
  background-color: #545454 !important;
  justify-content: center;
  border-color: #000 !important;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-width: 6px;
  border-radius: 0 0 20px 20px;
  margin-left: 3px;
  margin-bottom: 8px;

}

.seat4 label {
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px
}

.seat5 {
  width: 30px;
  height: 40px;
  background-color: #21bf73 !important;
  justify-content: center;
  border-color: #000 !important;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-width: 6px;
  border-radius: 0 0 20px 20px;
  margin-left: 3px;
  margin-bottom: 8px;
}

.seat5 label {
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px
}

.seat6 {
  width: 30px;
  height: 40px;
  background-color: #002ddc !important;
  justify-content: center;
  border-color: #000 !important;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-width: 6px;
  border-radius: 0 0 20px 20px;
  margin-left: 3px;
  margin-bottom: 8px;

}

.seat6 label {
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px
}

.seatFree label {
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px
}

.aisle {
  width: 35px;
  height: 40px;
  justify-content: center;
  margin-left: 3px;
  margin-bottom: 8px;
  background-color: #f5fcff !important;
  border: 0
}

.aisle label {
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: -9px;
  margin-top: -9px
}

.seatNone {
  width: 55px;
  height: 40px;
  background-color: #f5fcff !important;
  justify-content: center;
  border-color: #f5fcff !important;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-width: 6px;
  border-radius: 0 0 20px 20px;
  margin-left: 3px;
  margin-bottom: 8px;
}

.seatNonex {
  width: 4.5%;
  height: 40px;
  background-color: #f5fcff !important;
  justify-content: center;
  border-color: #f5fcff !important;
  border-bottom-width: 6px;
  border-left-width: 6px;
  border-right-width: 6px;
  border-radius: 0 0 20px 20px;
  margin-left: 3px;
  margin-bottom: 8px;
  color: #fff;
}

.seatNonex label {
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px
}

.seatNone label {
  text-align: center;
  vertical-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px
}

.shoppingBadge {
  margin-left: 4%;
}

.shoppingCart {
  min-width: 500px !important;
}

.shop {
  margin-top: 13%;
  z-index: 99999 !important;
  float: right !important;
  width: 20%;
}

@media (min-width: 1200px) {
  .company {
    flex: 0 0 8.3333333333%;
    max-width: 6.333333% !important;
  }
}

@media (min-width: 768px) {
  .company {
    flex: 0 0 8.3333333333%;
    max-width: 8.333333%;
  }
}

@media (min-width: 576px) {
  .company {
    flex: 0 0 8.3333333333%;
    max-width: 8.333333%;
  }
}


$product-details-bg: #fcfcfc;
$invoice-spacing: 1.45rem 0;
$invoice-card-body-padding: 2.5rem;
$border-color: #ebe9f1 !default;
$border-radius: 0.357rem !default;

@media print {
  body {
    padding: 32px;
    zoom: 75%;
  }
  table > * {
    border: solid #000 !important;
    border-width: 1px 0 0 1px !important;
  }
  @media print {
    hr {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  }

  @media print {
    .page-break {
      page-break-before: always;
    }
  }

  @page {
    size: auto;
  }

}

.img-zoom {
  overflow: hidden;
}

.img-zoom img {
  width: 450px;
  transition: transform 0.3s;
}

.img-zoom img:hover {
  transform: scale(5.5);
}

.swiper-pagination-bullet {
  background-color: red !important;
}

.swiper-button-next {
  background-color: red !important;
}

.swiper-button-prev {
  background-color: red !important;
}

.survey {
  max-width: 800px !important;
}

.flatpickr-time {
  height: 40px !important;
  border-top: 1px solid red !important;
}

